import React, { useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";

function PrivacyPolicy() {
    useEffect(()=>{
window.scroll(0,0)
    },[])
  return (
    <div>
      {/* <Header /> */}
      <div className="container">
      <h1 className="text-center">Privacy Policy</h1>
<div style={{marginTop:'50px'}}>
This privacy policy From Zaika Mart (Privacy Policy) describes the
      policies and procedures applicable to the collection, use, disclosure and
      protection of Your information shared with Us while You use the Platform,
      and for the purpose of this Privacy Policy "We", "Us", or "Our" refers to
      any of the Company both of them, wherever context so require and the terms
      “You”, “Your”, “Yourself” or “User” refer to user of the Platform. We
      value the trust You place in Us. That is why, We maintain reasonable
      security standards for securing the transactions and Your information.
      This Privacy Policy is an electronic record under the Information
      Technology Act, 2000 and the rules made thereunder. This Privacy Policy
      does not require any physical, electronic, or digital signature by Us and
      has been published in accordance with the provisions of the Information
      Technology Act, 2000 and the rules made thereunder that require publishing
      the privacy policy and terms of use on the Platform. Please read the
      Privacy Policy carefully prior to using or registering on the Platform or
      accessing any material, information or availing any Services through the
      Platform. This Privacy Policy specifies the manner in which Your
      information is collected, received, stored, processed, disclosed,
      transferred, dealt with or otherwise handled by Us. This Privacy Policy
      does not apply to information that You provide to, or that is collected
      by, any third-party through the Platform, and any Third-Party Sites that
      You access or use in connection with the Services offered on the Platform.
      By visiting the Platform or setting up/creating an user account (Account)
      on the Platform, You accept and agree to be bound by the terms and
      conditions of this Privacy Policy and consent to Us collecting, storing,
      processing, transferring and sharing information including Your Personal
      Information (defined below)in accordance with this Privacy Policy.
      Further, in case You are under the age of 18 years, You (i) accept and
      acknowledge that You are accessing the Platform through a parent or a
      legal guardian who is of a legal age to form a binding contract under the
      Indian Contract Act, 1872 and such person has accepted this Privacy Policy
      on Your behalf to bind You; and (ii) hereby acknowledge that You are
      accessing this Platform under the supervision of Your parent or legal
      guardian and have their express permission to use the Services. We may
      update this Privacy Policy in order to comply with the regulatory or
      administrative requirements. If We make any significant changes to this
      Privacy Policy, We will endeavour to provide You with reasonable notice of
      such changes, such as via prominent notice on the Platform or any other
      communication channels on record. To the extent permitted under applicable
      law, Your continued use of the Services after We publish or send a notice
      about the changes to this Privacy Policy shall constitute Your consent to
      the updated Privacy Policy. This Privacy Policy is incorporated into and
      subject to the terms of use available on the Platform (“Terms”) and shall
      be read harmoniously and in conjunction with the Terms. All capitalised
      terms used herein however not defined under this Privacy Policy shall have
      the meaning ascribed to them under the Terms. 1) Collection of
      Information: We collect various information from You when You access or
      visit the Platform, register or set up an Account on the Platform or use
      the Platform. You may browse certain sections of the Platform and the
      Content, without registering an Account on the Platform. However, to avail
      certain Services on the Platform, You are required to set up an Account on
      the Platform. This Privacy Policy applies to information(s), as mentioned
      below and collected about You: a) Personal Information: You may provide
      certain information to Us voluntarily while registering on the Platform
      for availing Services including but not limited to Your complete name,
      mobile number, email address, date of birth, gender, age, address details,
      proof of identity such as Permanent Account Number (PAN), passport,
      driving license, the voter's identity card issued by the Election
      Commission of India, or any other document recognized by the Government
      for identification, and any other information voluntarily provided through
      the Platform (“Personal Information”). The act of providing Your Personal
      Information is voluntary in nature and We hereby agree and acknowledge
      that We will collect, use, store, transfer, deal with and share such
      details in compliance with applicable laws and this Privacy Policy. b)
      Sensitive Personal Information: For the purpose of this PrivacyPolicy,
      Sensitive Personal Information consists of information relating to the
      following: i) passwords; ii) financial information such as bank account or
      credit card or debit card or other payment instrument details; iii)
      physical, physiological and mental health condition; iv) sexual
      orientation; v) medical records and history; vi) biometric information;
      vii) any details relating to the above as provided to a body corporate for
      providing services; and viii) any details relating to the above, received
      by a body corporate for processing, stored or processed under lawful
      contract or otherwise. ix) any other information that may be regarded as
      Sensitive Personal Information” as per the prevailing law for the time
      being in force. Provided that, any information that is freely available or
      accessible in public domain or furnished under the Right to Information
      Act, 2005 or any other law for the time being in force shall not be
      regarded as Sensitive Personal Information. You may be asked for the
      payment details to process payments for the Services. You may also be
      asked to provide certain additional information about Yourself on a case
      to case basis. c) Transactional Information: If You choose to avail the
      Services through the Platform, We will also collect and store information
      about Your transactions including transaction status, order history,
      number of transactions, details and Your behaviour or preferences on the
      Platform. All transactional information gathered by Us shall be stored on
      servers, log files and any other storage system owned by any of Us or by
      third parties. d) Location based information: When and if You download
      and/or use the Platform through Your mobile, tablet, and/or any other
      computer sources or electronic devices, We may receive information about
      Your location, Your IP address, including a unique identifier number for
      Your device. The information You provide may be used to provide You with
      location-based Services including but not limited to search results and
      other personalized content. If You permit the Platform to access Your
      location through the permission system used by Your device operating
      system, the precise location of Your device when the Platform is running
      in the foreground or background may be collected. You can withdraw Your
      consent at any time by disabling the location ­tracking functions on Your
      device. However, this may affect Your experience of certain
      functionalities on the Platform. In addition to the above, Your IP address
      is identified and used to also help diagnose problems with Our server,
      resolve such problems and administer the Platform. Your IP address is also
      used to help identify You and to gather broad demographic information. The
      primary goal in doing so is to provide You a safe, efficient, smooth, and
      customized experience on the Platform. The information collected allows Us
      to provide the Services and/or features on the Platform that most likely
      meet Your needs, and to customize the Platform to make Your experience
      safer and easier. More importantly, while doing so, We collect the above -
      mentioned Personal Information from You that We consider necessary for
      achieving this purpose. We may also collect certain non-personal
      information, such as Your internet protocol address, web request,
      operating system, browser type, other browsing information (connection,
      speed, connection type etc.), device type, the device's telephone number,
      URL, internet service provider, aggregate user data, software and hardware
      attributes, the URL of the previous website visited by You, list of
      third-party applications being used by You, pages You request, and cookie
      information, etc. which will not identify with You specifically, the
      activities conducted by You(“Non - Personal Information”),while You
      browse, access or use the Platform. We receive and store Non-Personal
      Information by the use of data collection devices such as “cookies” on
      certain pages of the Platform for various purposes, including
      authenticating Users, store information (including on Your device or in
      Your browser cache) about Your use of our Services, remembering User
      preferences and settings, determining the relevancy of content, delivering
      and measuring the promotional effectiveness, and promote trust and safety,
      analyzing site traffic and trends, and generally understanding the online
      behaviors and interests of people. Certain additional features may be
      offered on the Platform that are only available through use of a “cookie”.
      We place both permanent and temporary cookies in Your device. We may also
      use cookies from third party partners for marketing and analytics
      purposes. You are always free to decline such cookies if Your browser
      permits, although in that case, You may not be able to use certain
      features or Services being provided on the Platform. In general, You can
      browse the Platform without telling Us who You are or revealing any
      Personal Information about Yourself. In such a case, We will only collect
      and store the Non -Personal Information. Once You give us Your Personal
      Information, You are not anonymous to Us. Wherever possible, while
      providing the information to Us, We indicate which fields are mandatory
      and which fields are optional for You. You always have the option to not
      provide the Personal Information to Us through the Platform by choosing to
      not use a particular Service or feature being provided on the Platform,
      which requires You to provide such information. We may automatically track
      certain information about You based upon Your behaviour on the Platform.
      We use this information to do internal research on Your demographics,
      interests, and behaviour to better understand, protect and prove service
      to You. This information is compiled and analyzed by Us on an aggregated
      basis and not individually, in a manner that does not specifically
      identify You. If You choose to post messages on the Platform’s message
      boards, chat rooms or other message areas or leave feedback, We will
      collect and store such information You provide to Us. We retain this
      information as necessary to resolve disputes, provide customer support,
      respond to queries, and inquiries, and troubleshoot problems and improve
      the Services. If You send us correspondence, such as emails or letters, or
      if other users or third parties send us correspondence about Your
      activities or postings on the Platform, We may collect and retain such
      information into a file specific to You for responding to Your request and
      addressing concerns in relation to Your use of the Platform. We shall be
      entitled to retain Your Personal Information and other information for
      such duration as may be required for the purposes specified hereunder and
      will be used only in accordance with this Privacy Policy.
</div>
   
      </div>
      
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;

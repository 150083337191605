import React from 'react'
import Asset58 from '../images/Asset 58.png'

function WhatsApp() {
  return (
    <div>
          <a href="https://wa.me/+917276810640" class="whats_app" target='_blank'>
        <img src={Asset58} class="" />
      </a>
    </div>
  )
}

export default WhatsApp
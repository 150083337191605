import React from 'react'
import playstore from '../images/play-store.png'
import appstore from '../images/app-store.png'
import ScrollToTop from "react-scroll-to-top";
import WhatsApp from './WhatsApp';
import { Link } from 'react-router-dom';



function Footer() {
  return (
    <div>
      <div>
      <ScrollToTop smooth top="150" color="#fff"  />
      </div>
<WhatsApp/>

         {/* <!-- =====  FOOTER START  ===== --> */}
         <div class="footer section pt-40" style={{backgroundColor:'#F2F2F2',marginTop:'50px'}}>
      <div class="container">
        <div class="row">
          <div class="col-lg-3 footer-block">
            <h4 class="footer-title py-2">Information</h4>
            <ul>
              <li><a href="#">About Us</a></li>
              <li><a href="#">Delivery Information</a></li>
              <li><Link to={'/privacy_policy'}>Privacy Policy</Link></li>
              <li><a href="#">Terms & Conditions</a></li>
              <li><a href="#">Contact Us</a></li>
            </ul>
          </div>
          <div class="col-lg-3 footer-block">
            <h4 class="footer-title py-2">Services</h4>
            <ul>
              <li><a href="#">Returns</a></li>
              <li><a href="#">Site Map</a></li>
              <li><a href="#">Wish List</a></li>
              <li><a href="#">My Account</a></li>
              <li><a href="#">Order History</a></li>
            </ul>
          </div>
          <div class="col-lg-3 footer-block">
            <h4 class="footer-title py-2">Extras</h4>
            <ul>
              <li><a href="#">Brands</a></li>
              <li><a href="#">Gift Certificates</a></li>
              <li><a href="#">Affiliates</a></li>
              <li><a href="#">Specials</a></li>
              <li><a href="#">Newsletter</a></li>
            </ul>
          </div>
          <div class="col-lg-3 footer-block">
            <h4 class="footer-title py-2">Contacts</h4>
            <ul>
              <li class="email">Warehouse & Offices,</li>
              <li class="phone">(+123) 456 789
                <br/> (+024) 666 888</li>
              <li class="email">Contact@yourcompany.com</li>
            </ul>
          </div>
        </div>
        {/* <!-- =====  Newslatter ===== --> */}
        {/* <div class="newsletters mt-30">
          <div class="news-head pull-left">
            <h2>Subscribe for our offer news</h2>
          </div>
          <div class="news-form pull-right">
            <form onsubmit="return validatemail();" method="post">
              <div class="form-group required">
                <input name="email" id="email" placeholder="Enter Your Email" class="form-control input-lg" required="" type="email"/>
                <button type="submit" class="btn btn-default btn-lg">Subscribe</button>
              </div>
            </form>
          </div>
        </div> */}
        {/* <!-- =====  Newslatter End ===== --> */}
      </div>

      <div class="footer-bottom" style={{backgroundColor:'#F2F2F2'}}>
        <div class="container">
          <div class="row">
            <div class="col-12 col-lg-4 mt-20">
              <div class="section_title">payment option </div>
              <div class="payment-icon text-center">
                <ul>
                  <li><i class="fab fa-cc-paypal"></i></li>
                  <li><i class="fab fa-cc-visa"></i></li>
                  <li><i class="fab fa-cc-discover"></i></li>
                  <li><i class="fab fa-cc-mastercard"></i></li>
                  <li><i class="fab fa-cc-amex"></i></li>
                </ul>
              </div>
            </div>

            <div class="col-12 col-lg-4 mt-20">
              <div class="section_title">download app</div>
              <div class="app-download text-center">
                <ul class="app-icon">
                  <li><a href="#" title="playstore"><img src={playstore} alt="playstore" class="img-responsive"/></a></li>
                  {/* <li><a href="#" title="appstore"><img src={appstore} alt="appstore" class="img-responsive"/></a></li> */}
                </ul>
              </div>
            </div>
            <div class="col-12 col-lg-4 mt-20">
              <div class="section_title">Social media</div>
              <div class="social_icon text-center">
                <ul>
                  <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                  <li><a href="#"><i class="fab fa-google-plus-g"></i></a></li>
                  <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                  <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                  <li><a href="#"><i class="fas fa-rss"></i></a></li>
                </ul>
              </div>
            </div>
            <div class="col-12 ">
              <div class="copyright-part text-center pt-10 pb-10 mt-30">@Copyright 2022 | All Rights Reserved | Design & Developed by <a href="https://www.peaceinfotech.com/" target="_blank">Peaceinfotech Services Pvt Ltd</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- =====  FOOTER END  ===== --> */}
    </div>
  )
}

export default Footer
import logo from './logo.svg';
import './App.css';
// import Login from './components/user/login/Login';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
// import Home from './components/user/Home/Home';
// import ProfileUpdate from './components/user/ProfileUpdate/ProfileUpdate';
// import Category from './components/user/Top_categories_inside/Category';
// import Fetured from './components/user/Fetured_Product_insie/Fetured';
// import Profile from './components/user/login/Profile';
// import SubCategory from './components/user/Top_categories_inside/SubCategory';
// import IndividualProduct from './components/user/Top_categories_inside/IndividualProduct';
import { ToastContainer,Slide,Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import AllNotification from './components/user/MyNotifications/AllNotification';
// import AllOffers from './components/user/MyOffer/AllOffers';
// import SingleProductDetails from './components/user/Home/FeaturedCategories/SingleProductDetails';
// import MyCart from './components/user/MyCartItems/MyCart';
// import MyAddress from './components/user/Address/MyAddress';
// import MyAllOrder from './components/user/Order/MyAllOrders';
// import CancelledOrderHistory from './components/user/CancelledOrder/CancelledOrderHistory';
// import About from './components/user/About/About';
// import Contact from './components/user/ContactUs/Contact';
// import Thanks from './components/user/ThankYouPage/Thanks'
// import EditAddress from './components/user/Address/EditAddress';
import {lazy, Suspense} from 'react'
import Loader from './components/user/Home/Loader/Loader';
import PrivacyPolicy from './components/user/Privacypolicy/PrivacyPolicy';
import Header from './components/user/Home/Header';
import SearchPage from './components/user/Top_categories_inside/SearchPage';

const Home = lazy(() => import('./components/user/Home/Home'));
const About = lazy(() => import('./components/user/About/About'));
const Contact = lazy(() => import('./components/user/ContactUs/Contact'));
const Login = lazy(() => import('./components/user/login/Login'));
const AllNotification =lazy(() => import('./components/user/MyNotifications/AllNotification'));
const AllOffers = lazy(() => import('./components/user/MyOffer/AllOffers'));
const MyAllOrder = lazy(() => import('./components/user/Order/MyAllOrders'));
const CancelledOrderHistory = lazy(() => import('./components/user/CancelledOrder/CancelledOrderHistory'));
const MyCart = lazy(() => import('./components/user/MyCartItems/MyCart'));
const MyAddress =lazy(() => import('./components/user/Address/MyAddress'));
const EditAddress = lazy(() => import('./components/user/Address/EditAddress'));
const Thanks = lazy(() => import('./components/user/ThankYouPage/Thanks'));
const ProfileUpdate = lazy(() => import('./components/user/ProfileUpdate/ProfileUpdate'));
const Category = lazy(() => import('./components/user/Top_categories_inside/Category'));
const SingleProductDetails = lazy(() => import('./components/user/Home/FeaturedCategories/SingleProductDetails'));
const SubCategory = lazy(() => import('./components/user/Top_categories_inside/SubCategory'));
const  IndividualProduct= lazy(() => import('./components/user/Top_categories_inside/IndividualProduct'));
const  Fetured= lazy(() => import('./components/user/Fetured_Product_insie/Fetured'));




function App() {
  return (
    <div>
        <Router>
      <ToastContainer transition={Flip} />
      <Suspense fallback={<Loader/>}>
      <Header/>

      <Routes>
        <Route exact path="/about" element={<About/>} />
        <Route exact path="/contact_us" element={<Contact/>} />
        <Route exact path="/login" element={<Login/>} />
        <Route exact path="/my_notification" element={<AllNotification/>} />
        <Route exact path="/my_offers" element={<AllOffers/>} />
        <Route exact path="/my_orders/:id" element={<MyAllOrder/>} />
        <Route exact path="/my_orders/:id/history" element={<CancelledOrderHistory/>} />
        <Route exact path="/my_cart/:user_id" element={<MyCart/>} />
        <Route exact path="/my_cart/:user_id/my_address" element={<MyAddress/>} />
        <Route exact path="/my_cart/:user_id/my_address/:address_id" element={<EditAddress/>} />

        <Route exact path="/my_cart/:user_id/thanks_to_you" element={<Thanks/>} />

        <Route exact path="/profile_update" element={<ProfileUpdate/>} />
        <Route exact path="/categories/:id" element={<Category/>} />
        <Route exact path="/single_product_details/:indi_id" element={<SingleProductDetails/>} />

        <Route exact path="/categories/:id/sub_categories/:sub_id" element={<SubCategory/>} />
        <Route exact path="/categories/:id/sub_categories/:sb_id/:search_text" element={<SearchPage/>} />

        <Route exact path="/categories/:id/sub_categories/:sub_id/indivdulal_product/:product_id" element={<IndividualProduct/>} />
        <Route exact path="/all_products/:product_id" element={<Fetured/>} />
        <Route exact path="/privacy_policy" element={<PrivacyPolicy/>} />

        <Route exact path="/" element={<Home/>} />


        {/* <Route exact path="/product_details" element={<Fetured/>} /> */}


        </Routes>
      </Suspense>
        
        
      </Router>

</div>
  );
}

export default App;

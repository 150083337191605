import React, { useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import Header from "../Home/Header";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import Asset166 from "../images/Asset 166.png";
import Asset167 from "../images/Asset 167.png";
import Asset65 from "../images/Asset 65 (1).png";
import { toast } from "react-toastify";

function SearchPage() {
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [count, setCount] = useState(1);
  const [cardLoading, setCardLoading] = useState(false);
  const[nullMessage,setNullMessage]= useState(false);

  const navigate = useNavigate();

  const params = useParams();
  useEffect(() => {
    if ((params.search_text)) {
      getDataByID(params.search_text);
      window.scrollTo(0, 0);
    }
    console.log(params, "params");
  }, [params]);

  const getDataByID = (search_text) => {
    setCardLoading(true);
    let formData = new FormData(); //formdata object

    // const url = `https://192.168.1.16/vipdoor/UserApi/service_data?service_id=${id}`;
    const url = `https://zaikamart.com/grocery/UserApi/search?search=${search_text}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        console.log(response);
        if(response.data.message === "No Products Found"){
        setCardLoading(false);
        setNullMessage(true);
        }
        else{
          setCardLoading(false);
          setNullMessage(false);
          setSubCategoryData(response.data.search_products);
        }
      
        // setServiceName(response.data.result.service_link);
        // setServiceData(response.data.result);
        // localStorage.setItem("key6", id);
        // localStorage.setItem("key7",response.data.result.service_title);

        // localStorage.setItem('service_name',response.data.result.service_link)
      })

      .catch((error) => {
        console.log(error);
        setCardLoading(false);
      });
  };

  //add to Cart Items
  const add_to_cart = (count) => {
    // if (!localStorage.getItem("key2")) {
    //   toast.warning("You Have To Login First", {
    //     position: "top-center",
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: false,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    //   // props.showAlert("You Have to Login First", "danger");
    //   // localStorage.setItem("prevLocation",location.pathname)
    //   navigate("/login");
    // }

  };

  // For Count
  const removeCountHandler = () => {
    if (count === 1) {
      return;
    }
    setCount(count - 1);
  };
  return (
    <div>

      <div class="wrapper">
        {/* <Header /> */}
        {/* <!-- =====  BREADCRUMB STRAT  ===== --> */}
        <div class="breadcrumb section pt-60 pb-60">
          <div class="container">
            <h1 class="uppercase">Product</h1>
          </div>
        </div>
        {/* <!-- =====  BREADCRUMB END===== --> */}
        {nullMessage && (
        <>
          <div className="text-center mt-5 mb-5">
            <img src={Asset65} alt="img" />
          </div>
        </>
      )}
        <div class="product-section section ">
          {/* <!-- =====  CONTAINER START  ===== --> */}
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="category-page-wrapper mb-15 pb-10">
                  <div class="row"></div>
                </div>
                <div class="row">
                  {/* {subCategoryData &&
                    subCategoryData.map((items) => {
                      // console.log(Number(items.variants.product_variant_main_price)-Number(items.variants.product_variant_discount_price));
                      const dicounted_price =
                        Number(items.variants.product_variant_main_price) -
                        Number(items.variants.product_variant_discount_price);
                      return (
                        <>
                          <div class="product-layout  product-grid col-lg-3 col-6 ">
                            <div class="item">
                              <div class="product-thumb transition">
                                <div class="image">
                                  <div class="first_image">
                                    {" "}
                                    <Link
                                      to={`/categories/${items.product.main_category_id}/sub_categories/${items.product.primary_category_id}/indivdulal_product/${items.product.product_id}`}
                                    >
                                      {" "}
                                      <img
                                        src={`https://192.168.1.6/grocery/${items.product.product_image}`}
                                        alt="pure-spice-3"
                                        title="pure-spice-3"
                                        class="img-responsive top_img"
                                      />{" "}
                                    </Link>{" "}
                                  </div>
                                </div>
                                <div class="product-details">
                                  <div class="caption">
                                    <h4>
                                      <a href="product_detail_page.html">
                                        {items.product.primary_category_name}
                                      </a>
                                    </h4>
                                    <p class="price">
                                      Price :{" "}
                                      {
                                        items.variants
                                          .product_variant_main_price
                                      }
                                      /-
                                    </p>
                                    <p class="price">
                                      Discount Price :{" "}
                                      {
                                        items.variants
                                          .product_variant_discount_price
                                      }
                                      /-
                                    </p>
                                    <p class="price">
                                      Total Price : {dicounted_price}/-
                                    </p>

                                    <p class="desc">
                                      {items.product.product_description}
                                    </p>
                                    <div class="product_option">
                                      <div class="form-group required ">
                                        <select
                                          name="option[239]"
                                          id="input-option231"
                                          class="form-control"
                                        >
                                          <option value="">
                                            {" "}
                                            --- Please Select ---{" "}
                                          </option>
                                          <option value="">
                                            {
                                              items.variants
                                                .product_variant_weight
                                            }
                                          </option>
                                      
                                        </select>
                                      </div>
                                      <div class="row d-flex">
                                        <div class="col-md-6">
                                        <div class="form-group mb-2">
                                            <label
                                              for="staticEmail2"
                                              class=""
                                            >
                                              Email
                                            </label>
                                            <input
                                              type="text"
                                              readonly
                                              class="form-control "
                                              id="staticEmail2"
                                              value="email@example.com"
                                            />
                                          </div>
                                        </div>
                                         <div class="col-md-6">
                                         <button
                                            type="submit"
                                            class="btn btn-primary mb-2"
                                          >
                                            Confirm identity
                                          </button>
                                         </div>
                                        
                                       
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })} */}
                  {cardLoading && (
                    <>
                      <div class="container" >
                        <div class="row">
                        <div class=" col-md-3">
                          <div class="movie--isloading">
                            <div class="loading-image"></div>
                            <div class="loading-content">
                              <div class="loading-text-container">
                                <div class="loading-main-text"></div>
                                <div class="loading-sub-text"></div>
                              </div>
                              <div class="loading-btn"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="movie--isloading">
                            <div class="loading-image"></div>
                            <div class="loading-content">
                              <div class="loading-text-container">
                                <div class="loading-main-text"></div>
                                <div class="loading-sub-text"></div>
                              </div>
                              <div class="loading-btn"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="movie--isloading">
                            <div class="loading-image"></div>
                            <div class="loading-content">
                              <div class="loading-text-container">
                                <div class="loading-main-text"></div>
                                <div class="loading-sub-text"></div>
                              </div>
                              <div class="loading-btn"></div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="movie--isloading">
                            <div class="loading-image"></div>
                            <div class="loading-content">
                              <div class="loading-text-container">
                                <div class="loading-main-text"></div>
                                <div class="loading-sub-text"></div>
                              </div>
                              <div class="loading-btn"></div>
                            </div>
                          </div>
                        </div>
                        </div>
                     
                      </div>
                    </>
                  )}

                  {subCategoryData &&
                    subCategoryData.map((items) => {
         
                      return (
                        <>
                          <div className="col-md-6 col-xl-3 col-lg-4">
                            <div class="card mt-2" style={{ margin: "auto" }}>
                              <Link
                                to={`/categories/${items.main_category_id}/sub_categories/${items.primary_category_id}/indivdulal_product/${items.product_id}`}
                              >
                                <img
                                  class="card-img-top sub_cat"
                                  src={`https://zaikamart.com/grocery/${items.product_image}`}
                                  alt="Card image cap"
                                />
                              </Link>
                              <div class="card-body">
                                <h5 class="card-title">
                                  {items.primary_category_name}
                                </h5>
                                <p class="card-text">
                                  {items.product_description.slice(
                                    0,
                                    50
                                  )}
                                  ...
                                </p>
                                {/* <div class="price">
                                  Price :{" "}
                                  <span style={{ float: "right" }}>
                                    &#x20B9;
                                    {items.product_main_price}
                                    /-
                                  </span>
                                </div>
                                <div class="price">
                                  Discount Price :{" "}
                                  <span style={{ float: "right" }}>
                                    {" "}
                                    &#x20B9;
                                    {
                                      items.product_discount_price
                                    }
                                    /-
                                  </span>
                                </div>
                              */}

                           
                                <div class="d-flex justify-content-between ">
                                  <Link
                                    class="btn btn-primary"
                                    // onClick={add_to_cart}
                                    to={`/categories/${items.main_category_id}/sub_categories/${items.primary_category_id}/indivdulal_product/${items.product_id}`}
                                  >
                                    Order
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>

                {/* <div class="pagination-nav text-center mt-50">
            <ul>
              <li><a href="#"><i class="fas fa-angle-double-left"></i></a></li>
              <li><a href="#"><i class="fas fa-angle-left"></i></a></li>
              <li class="active"><a href="#">1</a></li>
              <li><a href="#">2</a></li>
              <li><a href="#">3</a></li>
              <li><a href="#"><i class="fas fa-angle-right"></i></a></li>
              <li><a href="#"><i class="fas fa-angle-double-right"></i></a></li>
            </ul>
          </div> */}
              </div>
            </div>
          </div>
          {/* <!-- =====  CONTAINER END  ===== --> */}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SearchPage;

import React from 'react'

function Loader() {
  return (
    <div>
  <div class="loder"></div>

    </div>
  )
}

export default Loader
import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Asset391 from "../images/Image-281x391.1-jpg.jpg";
import Asset50 from "../images/g_logo.png";
import { Base64 } from "js-base64";
import { toast } from "react-toastify";
import axios from "axios";

function Header() {
  let user_name = localStorage.getItem("key3");
  let user_id = localStorage.getItem("key2");
  let cart_length = localStorage.getItem("key17");
  let serach_id = localStorage.getItem("key_sear");
  let serach_text = localStorage.getItem("key_text");

  const [searchData, setSearchData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [text, setText] = useState("");

  const navigate = useNavigate();

  // let decodedUserData = Base64.atob(localStorage.getItem("%st_04$1"));
  // decodedUserData = JSON.parse(decodedUserData);
  // console.log(decodedUserData.user_firstname)

  function Logout() {
    localStorage.clear();
    navigate("/login");
    toast.info("You are Logout", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  }

  //Search To Page
  const serachPage = () => {
    let formData = new FormData(); //formdata object

    // const url = "https://192.168.1.16/vipdoor/UserApi/home_screen_data";
    // let url = `https://zaikamart.com/grocery/UserApi/search?search=${serach_text}`;

    let url = `https://zaikamart.com/grocery/UserApi/search?search=${text}`;

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios
      .get(url, formData, config)
      .then((response) => {
        // console.log(response.data.success);
      setSuggestions([]);

        if ((response.data.message = "Products Found")) {
          navigate(
            `/categories/${response.data.search_products[0].main_category_id}/sub_categories/${response.data.search_products[0].primary_category_id}/${text}`
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const loadSerachData = async () => {
      const response = await axios.get(
        "https://zaikamart.com/grocery/UserApi/search"
      );
      // console.log(response);
      setSearchData(response.data.search_products);
    };
    loadSerachData();
    if (typeof window !== undefined) {
      localStorage.getItem("key17");
    }
  }, []);

  const onChangeHendler = (text) => {
    if (text.length < 1) {
      setSuggestions([]);
      setText("");
      // localStorage.removeItem("key_text");
    } else {
      let matches = searchData.filter((user) => {
        const regex = new RegExp(`${text}`, "gi");
        return user.product_name.match(regex);
      });
      // console.log(matches, "match");
      setText(text);
      setSuggestions(matches);
      localStorage.removeItem("key_text");
    }
  };
  const onSuggestedHandler = (text, id) => {
    setText(text);
    setSuggestions([]);
    localStorage.setItem("key_sear", id);
    // localStorage.setItem("key_text", text);
  };

  return (
    <div>
      {/* For Mobile View  */}
      <nav class="navbar navbar-expand-lg navbar-light bg-light" id="mob_view">
        <Link className="navbar-brand" to="/">
          {" "}
          <img alt="Bigmarket" src={Asset50} className="logo_home" />{" "}
        </Link>{" "}
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            {user_id ? (
              <>
                <li class="nav-item active">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                    to="/"
                  >
                    &nbsp;Home <span class="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                  >
                    About
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    to="/contact_us"
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                  >
                    Contact
                  </NavLink>
                </li>
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {user_name && Base64.decode(user_name).slice(0, 8)}
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link to={`/my_cart/${user_id}`} class="dropdown-item">
                      <i class="fas fa-shopping-cart"></i>&nbsp;
                      <span id="cart-text">
                        My Basket {cart_length && Base64.decode(cart_length)}
                      </span>
                    </Link>
                    <Link to={`/my_orders/${user_id}`} class="dropdown-item">
                      <i class="fas fa-clipboard-check"></i>&nbsp;My Orders
                    </Link>
                    <Link to={"/my_offers"} class="dropdown-item">
                      <i class="fas fa-gift"></i>&nbsp;Offers
                    </Link>
                    <Link to={"/my_notification"} class="dropdown-item">
                      <i class="fas fa-bell"></i>&nbsp;Notifications
                    </Link>
                    {/* <div class="dropdown-divider"></div> */}
                    <a class="dropdown-item" role="button" onClick={Logout}>
                      <i class="fas fa-sign-out-alt"></i>&nbsp;Logout
                    </a>
                  </div>
                </li>
              </>
            ) : (
              <>
                <li class="nav-item active">
                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                  >
                    &nbsp;Home <span class="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                    to="/about"
                  >
                    &nbsp;About
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                    to="/contact_us"
                  >
                    &nbsp;Contact us
                  </NavLink>
                </li>
                <li class="nav-item">
                  <NavLink
                    className={({ isActive }) =>
                      isActive ? "nav-link acti" : "nav-link"
                    }
                    to="/login"
                  >
                    &nbsp;Login
                  </NavLink>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>

      {/* For Mobile View End  */}
      {/* <!-- =====  HEADER START  ===== --> */}
      <header id="header" className="section">
        <div className="container">
          <div className="header-top py-1">
            <div className="row align-items-center">
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <ul
                  className="header-top-left pull-left"
                  style={{ float: "right" }}
                >
                  {user_id && user_id ? (
                    <>
                      <li className="telephone">
                        <NavLink
                          to="/"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          &nbsp;Home
                        </NavLink>
                        <span className="menubar_vb"></span>
                      </li>
                      <li className="telephone">
                        <NavLink
                          to="/about"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          About
                        </NavLink>
                        <span className="menubar_vb"></span>
                      </li>
                      <li className="telephone">
                        <NavLink
                          to="/contact_us"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          Contact
                        </NavLink>
                        <span className="menubar_vb"></span>
                      </li>
                      <li className="language dropdown px-2">
                        {" "}
                        <span
                          className="dropdown-toggle"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          role="button"
                        >
                          {user_name && Base64.decode(user_name).slice(0, 8)}{" "}
                          <span className="caret"></span>{" "}
                        </span>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu1"
                        >
                          <li>
                            <Link to="/profile_update">
                              {" "}
                              <i class="fa fa-user"></i>&nbsp;My Profile
                            </Link>
                          </li>
                          <li>
                            <Link to={`/my_orders/${user_id}`}>
                              <i class="fas fa-clipboard-check"></i>&nbsp;My
                              Orders
                            </Link>
                          </li>
                          <li>
                            <Link to={"/my_notification"}>
                              <i class="fas fa-bell"></i>&nbsp;Notifications
                            </Link>
                          </li>
                          <li>
                            <Link to={"/my_offers"}>
                              <i class="fas fa-gift"></i>&nbsp;Offers
                            </Link>
                          </li>
                          <li>
                            <a role="button" onClick={Logout}>
                              <i class="fas fa-sign-out-alt"></i>&nbsp; Logout
                            </a>
                          </li>
                        </ul>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className="telephone">
                        <NavLink
                          to="/"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          &nbsp;Home
                        </NavLink>
                        <span className="menubar_vb"></span>
                      </li>
                      <li className="telephone">
                        <NavLink
                          to="/about"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          About
                        </NavLink>
                        <span className="menubar_vb"></span>
                      </li>
                      <li className="telephone">
                        <NavLink
                          to="/contact_us"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          Contact
                        </NavLink>
                        <span className="menubar_vb"></span>
                      </li>
                      <li class="login">
                        <NavLink
                          to="/login"
                          className={({ isActive }) =>
                            isActive ? "nav-link acti" : "nav-link"
                          }
                        >
                          &nbsp;Login
                        </NavLink>
                      </li>
                    </>
                  )}

                  {/* <li class="register">
                  <a href="register.html">Signup</a>
                </li> */}
                </ul>
              </div>
              {/* <div className="col-md-6">
              <ul className="header-top-right pull-right">
                <li className="telephone">
                  <a href="#"><i className="fa fa-phone"></i> +91 987-654-321</a> 
                </li>
                <li className="language dropdown px-2"> <span className="dropdown-toggle" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">Affan <span className="caret"></span> </span>
                  <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                    <li><a href="#">My Profile</a></li>
                    <li><a href="#">Order</a></li>
                    <li><a href="#">History</a></li>
                  </ul>
                </li>
              </ul>
            </div> */}
            </div>
          </div>
        </div>
        <div className="header section pt-15 pb-15">
          <div className="container">
            <div className="row">
              <div className="navbar-header col-2 header-bottom-left">
                {" "}
                <Link className="navbar-brand" to="/">
                  {" "}
                  <img
                    alt="Bigmarket"
                    src={Asset50}
                    className="logo_home"
                  />{" "}
                </Link>{" "}
              </div>
              <div className="col-10 header-bottom-right">
                <div className="header-menu">
                  <div className="responsive-menubar-block">
                    <span>Shop Now</span>
                    <span
                      className="menu-bar collapsed"
                      data-target=".navbar-ex1-collapse"
                      data-toggle="collapse"
                    >
                      <i className="fa fa-bars"></i>
                    </span>
                  </div>
                  <nav id="menu" className="navbar">
                    <div className="collapse navbar-collapse navbar-ex1-collapse">
                      <ul className="nav navbar-nav main-navigation">
                        <li className="main_cat dropdown active">
                          {" "}
                          <a href="#">Grocery & Staples</a>
                          <div className="dropdown-menu megamenu column4">
                            <div className="dropdown-inner">
                              <ul className="list-unstyled childs_1">
                                <li className="dropdown active">
                                  <a href="#">Daal & Pulses</a>
                                  <div className="dropdown-menu">
                                    <div className="dropdown-inner">
                                      <ul className="list-unstyled childs_2">
                                        <li className="active">
                                          <a href="#">Arhar</a>
                                        </li>
                                        <li>
                                          <a href="#">Masoor</a>
                                        </li>
                                        <li>
                                          <a href="#">Moong</a>
                                        </li>
                                        <li>
                                          <a href="#">Rajma & Chana</a>
                                        </li>
                                        <li>
                                          <a href="#">Urad</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <ul className="list-unstyled childs_1">
                                <li className="dropdown active">
                                  <a href="#">Dry Fruits & Nuts</a>
                                  <div className="dropdown-menu">
                                    <div className="dropdown-inner">
                                      <ul className="list-unstyled childs_2">
                                        <li className="active">
                                          <a href="#">Akhrot & Figs</a>
                                        </li>
                                        <li>
                                          <a href="#">Almonds & Cashews</a>
                                        </li>
                                        <li>
                                          <a href="#">Nuts & Seeds</a>
                                        </li>
                                        <li>
                                          <a href="#">Other Dry Fruits</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <ul className="list-unstyled childs_1">
                                <li className="dropdown active">
                                  <a href="">Edible Oils</a>
                                  <div className="dropdown-menu">
                                    <div className="dropdown-inner">
                                      <ul className="list-unstyled childs_2">
                                        <li className="active">
                                          <a href="#">
                                            Groundnut & Coconut Oil
                                          </a>
                                        </li>
                                        <li>
                                          <a href="#">Health Oils</a>
                                        </li>
                                        <li>
                                          <a href="#">Mustard Oils</a>
                                        </li>
                                        <li>
                                          <a href="#">Soyabean Oils</a>
                                        </li>
                                        <li>
                                          <a href="#">Sunflower Oils</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <ul className="list-unstyled childs_1">
                                <li className="dropdown active">
                                  <a href="#">Riced cauliflower</a>
                                  <div className="dropdown-menu">
                                    <div className="dropdown-inner">
                                      <ul className="list-unstyled childs_2">
                                        <li className="active">
                                          <a href="#">Basmati</a>
                                        </li>
                                        <li>
                                          <a href="#">Millet & Others</a>
                                        </li>
                                        <li>
                                          <a href="#">Poha</a>
                                        </li>
                                        <li>
                                          <a href="#">Sonamasuri & Kolam</a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div className="menu-image">
                                {" "}
                                <img
                                  src={Asset391}
                                  alt=""
                                  title=""
                                  className="img-thumbnail"
                                />{" "}
                              </div>
                            </div>
                          </div>
                        </li>
                        <li className="main_cat dropdown">
                          {" "}
                          <a href="#">Personal Care</a>
                          <div className="dropdown-menu megamenu column1">
                            <div className="dropdown-inner">
                              <ul className="list-unstyled childs_1">
                                <li className="main_cat active">
                                  {" "}
                                  <a href="#">Deos & Perfumes</a>{" "}
                                </li>
                                <li className="main_cat">
                                  {" "}
                                  <a href="#">Hair Care</a>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="main_cat dropdown">
                          {" "}
                          <a href="#">Biscuits, Snacks & Chocolates</a>
                          <div className="dropdown-menu megamenu column1">
                            <div className="dropdown-inner">
                              <ul className="list-unstyled childs_1">
                                <li className="main_cat active">
                                  {" "}
                                  <a href="#">Biscuits & Cookies</a>{" "}
                                </li>
                                <li className="main_cat">
                                  {" "}
                                  <a href="">Chips & Crisps</a>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="main_cat dropdown">
                          {" "}
                          <a href="#">Household Needs</a>
                          <div className="dropdown-menu megamenu column1">
                            <div className="dropdown-inner">
                              <ul className="list-unstyled childs_1">
                                <li className="main_cat active">
                                  {" "}
                                  <a href="#">Cleaning Tools & Brushes</a>{" "}
                                </li>
                                <li className="main_cat">
                                  {" "}
                                  <a href="#">Home & Car Fresheners</a>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="main_cat dropdown">
                          {" "}
                          <a href="#">Breakfast & Dairy</a>
                          <div className="dropdown-menu megamenu column1">
                            <div className="dropdown-inner">
                              <ul className="list-unstyled childs_1">
                                <li className="main_cat active">
                                  {" "}
                                  <a href="#">Breakfast Cereal & Mixes</a>{" "}
                                </li>
                                <li className="main_cat">
                                  {" "}
                                  <a href="#">Paneer & Curd</a>{" "}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                        <li className="main_cat thumb">
                          {" "}
                          <a href="#">Fruits store </a>
                          <div className="dropdown-menu">
                            <div className="dropdown-inner">
                              {" "}
                              <img
                                src=""
                                alt=""
                                title=""
                                className="img-thumbnail"
                              />{" "}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="header-link-search">
                  <div className="header-search">
                    <div className="actions">
                      <button
                        type="submit"
                        title="Search"
                        className="action search"
                        id="head-search"
                      ></button>
                    </div>
                    <div id="search" className="input-group">
                      <input
                        id="search-input"
                        name="search"
                        className="form-control input-lg"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="button-addon2"
                        autoComplete="off"
                        onChange={(e) => onChangeHendler(e.target.value)}
                        value={!serach_text ? text : serach_text}
                      />
                      <span className="input-group-btn">
                        <button
                          type="button"
                          className="btn btn-default btn-lg"
                          onClick={serachPage}
                          style={{ cursor: "pointer" }}
                        >
                          Search
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
         
                {user_id && (
                  <>
                    <div className={cart_length ? "shopcart mt-t" : "shopcart"}>
                      <div id="cart" className="btn-block  ipad_view">
                        <Link
                          to={`/my_cart/${user_id}`}
                          className="btn"
                          data-target="#cart-dropdown"
                          data-toggle="collapse"
                          aria-expanded="true"
                        >
                          <span id="shippingcart">
                            My Basket{" "}
                            {cart_length && Base64.decode(cart_length)}
                          </span>
                        </Link>
                        {/* <Link
                          to={`/my_cart/${user_id}`}
                          className="cart_responsive btn"
                        >
                        
                          <span id="cart-text">My basket  {cart_length && Base64.decode(cart_length)}</span>
                       
                        
                        </Link> */}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div>
                  {suggestions &&
                    suggestions.map((item, i) => {
                      console.log(item);
                      return (
                        <>
                          <div className="">
                            <div className="col-md-12 serch_div">
                              <div
                                className="suggestte"
                                onClick={() =>
                                  onSuggestedHandler(
                                    item.product_name,
                                    item.product_id
                                  )
                                }
                              >
                                {item.product_name}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
            </div>
          </div>
        </div>
        {/* <div className="header-static-block">
          <div className="container">
            <div className="row">
              <div className="icon-block">
                <div className="home_icon">
                  <a href="index.html">
                    <i className="fa fa-home"></i>Home
                  </a>
                </div>
                <div className="search_icon">
                  <a href="#">
                    <i className="fa fa-search"></i>Search
                  </a>
                </div>
                <div className="cart_icon"></div>
                {!user_id && <>
                  <div className="login_icon">
                  <a href="#">
                    <i className="fa fa-user"></i>Login
                  </a>
                </div>
                </>}
             
                <div className="telephone_icon">
                  <a href="#">
                    <i className="fa fa-phone"></i>Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </header>
      {/* <!-- =====  HEADER END  ===== --> */}
    </div>
  );
}

export default Header;
